import InfoOutlined from "@mui/icons-material/InfoOutlined";
import LockIcon from "@mui/icons-material/Lock";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {
  Link as RouterLink,
  navigate,
  RouteComponentProps,
} from "@reach/router";
import Cover from "components/Cover";
import React, { useEffect, useMemo, useState } from "react";
import { getNovelById } from "services/api";
import { Novel, User } from "services/models";
import PostList from "../posts/PostList";

const PREFIX = "SingleNovel";

const classes = {
  novelGrid: `${PREFIX}-novelGrid`,
  novelTitle: `${PREFIX}-novelTitle`,
  novelDescription: `${PREFIX}-novelDescription`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.novelGrid}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.novelTitle}`]: {
    wordBreak: "keep-all",
  },

  [`& .${classes.novelDescription}`]: {
    wordBreak: "keep-all",
    overflowWrap: "break-word",
  },
}));

interface MatchingProps {
  novelId: string;
  user: User;
  children: React.ReactNode;
}

export default function SingleNovel({
  novelId,
  user,
}: RouteComponentProps<MatchingProps>) {
  const [novel, setNovel] = useState<Novel>();
  useEffect(() => {
    if (!novelId) return;
    getNovelById(novelId).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        navigate("/");
      } else {
        setNovel(result);
      }
    });
  }, [novelId]);

  const descriptionParagraphs = useMemo(() => {
    const urlPattern =
      /(^|[\s\n]|<[A-Za-z]*\/?>)((?:https?):\/\/[-A-Z0-9+\u0026\u2019@#/%?=()~_|!:,.;]*[-A-Z0-9+\u0026@#/%=~()_|])/gi;

    return novel
      ? novel.description.split("\n").map((line, idx) => (
          <Typography
            key={idx}
            paragraph
            className={classes.novelDescription}
            dangerouslySetInnerHTML={{
              __html: line.replace(
                urlPattern,
                "$1<a href='$2' target='_blank' rel='noopener'>$2</a>"
              ),
            }}
          />
        ))
      : "";
  }, [novel]);
  return (
    <StyledGrid container spacing={4} className={classes.novelGrid}>
      <Grid item xs={3} sm={4}>
        {novel ? (
          <Cover filename={novel.cover.url} />
        ) : (
          <Skeleton variant="rectangular" width="100%">
            <Cover filename="" />
          </Skeleton>
        )}
      </Grid>
      <Grid item xs={9} sm={8}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          className={classes.novelTitle}
        >
          {novel ? novel.title : <Skeleton />}
          {novel && !novel.published_at && <LockIcon />}
        </Typography>
        <Typography variant="h5" component="span" gutterBottom>
          {novel ? (
            <Link
              component={RouterLink}
              to={`/authors/${novel.author.id}`}
              color="#000000"
            >
              {novel.author.display_name} <InfoOutlined fontSize="small" />
            </Link>
          ) : (
            <Skeleton />
          )}
        </Typography>
        <Box paddingTop={2}>{descriptionParagraphs}</Box>
        <Stack direction="row" spacing={1}>
          {novel?.tags.map((tag) => (
            <Chip
              component={RouterLink}
              label={`#${tag.title}`}
              key={tag.title}
              variant="outlined"
              to={`/tags/${tag.id}`}
              clickable
            />
          ))}
        </Stack>
        {user && user.id === novel?.author.id && (
          <Box paddingTop={2}>
            <Button
              variant="outlined"
              component={RouterLink}
              to={`/novels/${novelId}/edit`}
            >
              작품 정보 수정
            </Button>
          </Box>
        )}
        {user && user.id === novel?.author.id && (
          <Box paddingTop={2}>
            <Button
              variant="outlined"
              component={RouterLink}
              to={`/novels/${novelId}/posts/new`}
            >
              새 회차 등록
            </Button>
          </Box>
        )}
      </Grid>
      {novel && (
        <PostList
          posts={novel.posts}
          editable={user && user.id === novel?.author.id}
        />
      )}
    </StyledGrid>
  );
}
